/* src/components/css/About.css */

.about {
  padding: 4rem 2rem;
  background-color: #f2e3d1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.199);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.about-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-right {
  flex: 2;
  padding: 2rem;
}

.about-right h2 {
  font-size: 2.5rem;
  font-family: 'Source Serif 4', serif; /* Fonte secundária */
  font-style: italic; /* Estilo itálico */
  color: #2E3191; /* Azul do evento */
  margin-bottom: 1rem;
}

.about-right p {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #666;
}

.highlight {
  font-family: 'Source Serif 4', serif; /* Fonte secundária */
  font-style: italic; /* Estilo itálico */
  color: #2E3191; /* Azul do evento */
}

.eyes-on {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
  }

  .about-left, .about-right {
    padding: 3rem;
  }

  .about-right h2 {
    font-size: 3rem;
  }

  .about-right p {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .about {
    padding: 2rem 1rem;
  }

  .about-content {
    flex-direction: column;
    padding: 1rem;
  }

  .about-left, .about-right {
    padding: 1rem;
  }

  .about-right h2 {
    font-size: 1.75rem; /* Reduced font size */
  }

  .about-right p {
    font-size: 0.875rem; /* Reduced font size */
  }

  .about-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

.download-link {
  color: #ffffff; /* Texto branco */
  background-color: #2E3191; /* Azul do evento */
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: inline-block;
  font-weight: bold;
}

.download-link:hover {
  color: #ffffff;
  background-color: #1F2370; /* Azul mais escuro no hover */
}

}

@media (max-width: 480px) {
  .about {
    padding: 1rem 0.5rem;
  }

  .about-content {
    padding: 0.5rem;
  }

  .about-left, .about-right {
    padding: 0.5rem;
  }

  .about-right h2 {
    font-size: 1.25rem; /* Further reduced font size */
  }

  .about-right p {
    font-size: 0.75rem; /* Further reduced font size */
  }

  .about-buttons {
    gap: 0.5rem;
  }

  .primary-btn {
    padding: 0.25rem; /* Further reduced padding */
  }
}

@media (min-width: 768px) {
    .about-content {
        flex-direction: row;
    }

    .about-left, .about-right {
        padding: 3rem;
    }

    .about-right h2 {
        font-size: 3rem;
    }

    .about-right p {
        font-size: 1.25rem;
    }
}

@media (max-width: 480px) {
    .about-right h2 {
        font-size: 2rem;
    }

    .about-right p {
        font-size: 1rem;
    }
}