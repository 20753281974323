/* Estilos Gerais para o Carrossel */
.hero-section {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #F2E3D1;
  padding: 20px;
}

/* Swiper Estilo */
.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 95%; /* Increased width */
  max-width: 900px; /* Increased max-width */
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.5;
  filter: blur(4px);
  transform: scale(0.9);
}

.swiper-slide-active {
  transform: scale(1);
  opacity: 1;
  filter: blur(0);
}

.slide-content {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

/* Imagem e Sobreposição */
.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8);
}

.text-overlay {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background: rgba(46, 49, 145, 0.8);
  padding: 15px 30px;
  border-radius: 12px;
  text-align: center;
  width: 80%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.text-overlay:hover {
  background: rgba(229, 80, 65, 0.8);
}

.text-overlay h4 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
  transition: color 0.3s ease;
}

.text-overlay h4 a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.text-overlay h4 a:hover {
  color: #F2E3D1;
}

/* Efeito de Zoom Sutil na Imagem */
.swiper-slide-active .slide-image {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

/* Responsividade */
@media (min-width: 768px) {
  .hero-section {
    height: 100vh;
  }

  .swiper-slide {
    height: 80vh;
  }

  .text-overlay h4 {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .text-overlay {
    padding: 10px 20px;
    width: 90%;
  }

  .text-overlay h4 {
    font-size: 1.2rem;
  }
}
