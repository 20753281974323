.hotels {
  background-color: #F2E3D1;
  padding: 40px 20px;
  max-width: 1200px;
  margin: auto;
  font-family: 'Inter', sans-serif;
  color: #333;
}

.hotels h2 {
  text-align: center;
  font-size: 2.5em;
  color: #2E3191;
  margin-bottom: 30px;
  font-weight: bold;
}

.hotels-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.hotel-card {
  background-color: #ffffffa4;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.hotel-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.hotel-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.hotel-card h3 {
  font-size: 1.5em;
  color: #2E3191;
  margin-bottom: 10px;
}

.hotel-card p {
  font-size: 1.1em;
  color: #555;
  margin: 5px 0;
  line-height: 1.8;
}

.hotel-website {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.hotel-website:hover {
  background-color: #0056b3;
}
.phone {
  font-weight: bold;
  color: #2E3191;
  font-size: 1.2em;
}

.voucher {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 15px;
  background: linear-gradient(90deg, #FFD700, #FFC107);
  color: #333;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.1em;
  text-transform: uppercase;
}


@media (max-width: 768px) {
  .hotels h2 {
    font-size: 2em;
  }
  .hotel-image {
    height: 250px;
  }
  .hotel-card p {
    font-size: 1em;
  }
}
