.prices {
  background-color: #f2e3d1;
  padding: 2rem 1rem;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.prices h2 {
  color: #2e3191;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.prices .description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #555;
}

.price-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  justify-content: center;
}

.price-card {
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.price-card.selected {
  border-color: #e55041;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.price-card h3 {
  font-size: 1.5rem;
  color: #2e3191;
  margin-bottom: 1rem;
  font-weight: bold;
}

.price-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.current-price {
  font-size: 2rem;
  color: #e55041;
  font-weight: bold;
}

.future-price {
  font-size: 1rem;
  color: #999;
  margin-top: 0.5rem;
}

.future-price span {
  font-weight: bold;
  color: #555;
}

.details {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.highlight {
  font-size: 1.1rem;
  font-weight: bold;
  color: #e55041;
}

.price-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsividade */
@media (max-width: 768px) {
  .price-grid {
    grid-template-columns: 1fr;
  }

  .price-card {
    padding: 1rem;
  }
}
