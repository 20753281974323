/* src/components/css/Maintenance.css */
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F2E3D1;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.maintenance-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.maintenance-content h1 {
  font-size: 2.5rem;
  color: #2E3191;
  margin-bottom: 1rem;
}

.maintenance-content p {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 0.5rem;
}