.sponsors {
  background-color: #e55041; /* Cor do tema */
  padding: 1rem 0;
}

.sponsors-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor {
  flex: 1 1 auto;
  margin: 0.5rem;
  max-width: 120px; /* Reduz para dispositivos pequenos */
}

.sponsor img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .sponsor {
    max-width: 100px;
  }
}
