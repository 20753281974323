.schedule-section {
  background-color: #F2E3D1;
  padding: 2rem 20rem; /* Reduzido para dispositivos menores */
  font-family: 'Inter', sans-serif;
}

.schedule-section h2 {
  color: #2E3191;
  text-align: center;
  font-size: 2rem; /* Ajuste menor para telas pequenas */
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 0.5rem; /* Reduzir espaço entre os botões */
  margin-bottom: 2rem;
  flex-wrap: nowrap; /* Impedir quebra para linha adicional */
}

.tab {
  padding: 0.4rem 0.8rem; /* Botões menores */
  border: 2px solid #2E3191; /* Contorno dos botões */
  border-radius: 15px; /* Borda mais arredondada */
  background-color: #fff;
  color: #2E3191;
  font-weight: bold;
  font-size: 0.9rem; /* Fonte menor */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tab.active,
.tab:hover {
  background-color: #2E3191; /* Cor ativa */
  color: #fff;
}
.schedule-content {
  max-width: 100%; /* Fluido para telas pequenas */
  margin: 0 auto;
  padding: 0 1rem; /* Adicionar espaçamento interno */
}

.theme-section {
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.theme-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #E55041;
  color: #fff;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.theme-title {
  margin: 0;
  font-size: 1.2rem; /* Ajustado para telas menores */
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.theme-details {
  padding: 1rem;
}

.schedule-item {
  display: grid;
  grid-template-columns: 1fr 80px 3fr; /* Coluna para horas, fotos e detalhes */
  align-items: center;
  gap: 1rem; /* Espaço entre as colunas */
  margin-bottom: 1rem;
}

.time {
  font-size: 1rem;
  font-weight: bold;
  color: #2E3191;
  text-align: center;
}
.details h4 {
  margin: 0;
  color: #E55041;
  font-size: 1rem; /* Reduzido para telas pequenas */
}

.details p {
  margin: 0.5rem 0 0;
  color: #7f8c8d;
}
.speaker-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.details {
  display: flex;
  flex-direction: column;
}

.details h4 {
  margin: 0;
  color: #E55041; /* Título da sessão */
  font-size: 1.2rem;
}

.details p {
  margin: 0.5rem 0 0;
  color: #7f8c8d; /* Nome do palestrante mantém a cor original */
  font-size: 1rem;
  text-align: left; /* Alinhado à esquerda */
}

.details-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap; /* Reorganizar conteúdo em telas pequenas */
}

.photo-column {
  flex-shrink: 0;
  margin-bottom: 1rem; /* Espaço entre elementos */
}

.text-column {
  flex-grow: 1;
}

.speaker-photo {
  width: 70px; /* Reduzido para telas pequenas */
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.special-session {
  background-color: #e3f2fd;
  border: 1px solid #90caf9;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  margin: 1.5rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.special-session .time {
  font-size: 1rem; /* Ajustado para telas menores */
  color: #1565c0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.details h4 {
  margin: 0;
  color: #2E3191; /* Azul para o título da sessão */
  font-size: 1.2rem;
  text-align: left; /* Alinhado à esquerda */
}

.moderators-section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.moderators-section h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  color: #2E3191; /* Azul */
}

.moderators-section ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.moderators-section ul li {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #555;
}

.moderators-section ul li:last-child {
  margin-bottom: 0; /* Remove margem do último item */
}

.theme-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #E55041;
  color: #fff;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  position: relative; /* Para posicionar o ícone */
}

.theme-title {
  margin: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.theme-icon {
  font-size: 1rem;
  margin-left: auto; /* Mantém o ícone alinhado à direita */
  transition: transform 0.3s ease; /* Animação suave para o ícone */
}

.theme-header:hover .theme-icon {
  transform: scale(1.2); /* Efeito ao passar o mouse */
}
.checkin-section {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9; /* Fundo claro para destaque */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.checkin-section .time {
  font-size: 1rem;
  font-weight: bold;
  color: #2E3191; /* Azul escuro */
  margin-right: 1rem;
}

.checkin-section .details h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #E55041; /* Vermelho suave */
}

/* Responsividade */
@media (max-width: 768px) {
  .checkin-section {
    padding: 0.8rem;
  }

  .checkin-section .time {
    font-size: 0.9rem;
  }

  .checkin-section .details h4 {
    font-size: 1rem;
  }
}


/* Ajustes de responsividade */
@media (max-width: 768px) {
  .moderators-section {
    padding: 0.8rem;
  }

  .moderators-section h4 {
    font-size: 1rem;
  }

  .moderators-section ul li {
    font-size: 0.8rem;
  }
}


/* Responsividade para telas menores */
@media (max-width: 768px) {

  .schedule-section {
    padding: 1rem; /* Reduzir padding para telas pequenas */
  }

  .tabs {
    flex-direction: column; /* Reorganizar tabs verticalmente */
  }

  .tab {
    width: 100%; /* Expandir largura */
    text-align: center;
  }

  .theme-title {
    font-size: 1rem;
  }

 .schedule-item {
    grid-template-columns: 1fr 60px 2fr; /* Ajustar proporções para telas menores */
    gap: 0.5rem;
  }

.time {
    font-size: 0.9rem;
  }

  .speaker-photo {
    width: 50px;
    height: 50px;
  }

  .special-session {
   background-color: #e3f2fd; /* Azul claro */
  border: 1px solid #90caf9;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  margin: 1.5rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .special-session .time {
     font-size: 1rem; /* Ajuste para tamanhos menores */
  font-weight: bold;
  color: #1565c0; /* Azul mais escuro */
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .special-session h4 {
 font-size: 1.3rem;
  color: #0d47a1; /* Azul escuro */
  margin: 0;
  }
    .details h4 {
    font-size: 1rem;
  }
}
.pause-section {
  background-color: #fce4ec; /* Rosa suave para pausas */
  border: 1px solid #f48fb1; /* Borda em tom complementar */
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;
  position: relative; /* Para alinhar ícones */
}

.pause-section h4 {
  color: #d81b60; /* Destaque no texto */
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.pause-section p {
  color: #ad1457; /* Tom mais escuro para subtítulo */
  font-size: 1.1rem;
  margin: 0.5rem 0 0;
}

.pause-section-icon {
  font-size: 2rem;
  color: #f06292; /* Ícone destacado */
  position: absolute;
  top: -1rem;
  left: calc(50% - 1rem);
}

.bar-block {
  background-color: #f48fb1; /* Barra para bloqueio */
  height: 8px;
  border-radius: 10px 10px 0 0;
}
.moderators-section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.moderators-section h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
  color: #2E3191; /* Azul escuro */
}

.moderators-section ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.moderators-section ul li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #555;
}

.moderators-section ul li:last-child {
  margin-bottom: 0;
}
.footer-note {
  margin-top: 1rem;
  padding: 0.8rem; /* Reduzir o espaçamento interno */
  background-color: #f9f9f9; /* Fundo claro para contraste */
  border-left: 4px solid #E55041; /* Linha de destaque */
  font-size: 0.8rem; /* Texto menor */
  line-height: 1; /* Espaçamento mais compacto entre linhas */
  color: #555; /* Tom neutro para o texto */
  border-radius: 5px; /* Canto arredondado */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra subtil */
  text-align: justify; /* Justificar o texto para um visual mais elegante */
}

.footer-note strong {
  color: #2E3191; /* Azul escuro para o destaque */
}
.course-section {
  background-color: #fff9f3; /* Fundo suave e acolhedor */
  border: 1px solid #ffb997; /* Destaque com cor complementar */
  border-radius: 12px; /* Bordas arredondadas para suavidade */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve para destaque */
  padding: 2rem;
  margin-bottom: 2rem; /* Espaço entre cursos */
  font-family: 'Inter', sans-serif; /* Fonte moderna */
  text-align: left;
}

.course-section h3 {
  font-size: 1.8rem; /* Tamanho maior para o título */
  color: #e55041; /* Cor vibrante e elegante */
  text-align: center; /* Centralização do título */
  margin-bottom: 1rem;
  text-transform: uppercase; /* Letras maiúsculas para destaque */
  
}

.course-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555; /* Cor suave para o texto */
  margin: 0.5rem 0;
  
}

.course-section strong {
  color: #2e3191; /* Azul escuro para destaques */
}

.course-program {
  margin-top: 2rem;
}

.course-program h4 {
  font-size: 1.5rem;
  color: #e55041; /* Cor vibrante */
  text-align: center;
  margin-bottom: 1rem;
}

.course-program ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.course-program ul li {
  font-size: 1rem;
  color: #333; /* Cor neutra para itens */
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  background-color: #fef8f5; /* Fundo leve para os itens */
  border-left: 4px solid #e55041; /* Barra de destaque */
  border-radius: 6px; /* Bordas arredondadas */
  transition: transform 0.2s ease-in-out; /* Animação ao passar o mouse */
}

.course-program ul li:hover {
  transform: translateX(10px); /* Animação ao passar o mouse */
  background-color: #ffe4dc; /* Fundo em destaque ao passar o mouse */
}

.course-section .coordinator-section,
.course-section .trainer-section {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9; /* Fundo suave */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

.coordinator-section strong,
.trainer-section strong {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #2e3191; /* Azul para subtítulos */
}

.course-section .coordinator-section p,
.course-section .trainer-section p {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #555; /* Texto neutro */
}
.special-notice {
  background-color: #fff3cd; /* Fundo amarelo claro */
  color: #856404; /* Texto amarelo escuro */
  padding: 1.5rem;
  border: 1px solid #ffeeba; /* Borda em tom complementar */
  border-radius: 10px;
  margin: 1.5rem 0;
  text-align: left;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.notice-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #d9534f; /* Vermelho para o título */
  margin-bottom: 0.5rem;
  text-align: center;
}

.notice-subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #5a6268; /* Cinza escuro */
  margin-bottom: 1rem;
  text-align: center;
}

.notice-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #495057; /* Tom neutro */
}



/* Responsividade */
@media (max-width: 768px) {
  .course-section {
    padding: 1.5rem; /* Reduzir padding em dispositivos menores */
  }

  .course-section h3 {
    font-size: 1.5rem; /* Ajustar tamanho do título */
  }

  .course-program h4 {
    font-size: 1.3rem; /* Ajustar tamanho dos subtítulos */
  }

  .course-program ul li {
    font-size: 0.9rem; /* Texto menor em dispositivos móveis */
  }
}




/* Responsividade para telas muito pequenas */
@media (max-width: 480px) {
  .schedule-section h2 {
    font-size: 1.5rem;
  }

  .time {
    font-size: 0.9rem;
  }

  .details h4 {
    font-size: 0.9rem;
  }
  .special-session {
    padding: 0.8rem;
  }

  .special-session .time {
    font-size: 0.9rem;
  }

  .special-session h4 {
    font-size: 1.1rem;
  }
}
