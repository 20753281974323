/* src/components/css/ContactForm.css */
.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form input,
.contact-form textarea {
    background-color: #F2E3D1;
    border: none;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: #333;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    border: 2px solid #2E3191;
}

.contact-form button {
    background-color: #2E3191;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #1E226D;
}
