.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2e3d1;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-container {
  cursor: pointer;
}

.logo-large {
  height: 50px;
}

.desktop-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px; /* Espaçamento entre itens */
}

.menu-links {
  display: flex;
  gap: 20px; /* Espaçamento entre links */
}

.menu-item {
  text-decoration: none;
  color: #2e3191;
  font-size: 1rem;
  font-weight: bold;
}

.menu-item:hover {
  color: #e55041;
}

.inscription-highlight {
  background-color: #e55041;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
}

.inscription-highlight:hover {
  background-color: #d43f3a;
}

/* Burger Menu Styles */
.bm-burger-button {
  display: none; /* Esconde o botão em telas maiores */
  position: fixed;
  width: 30px;
  height: 24px;
  right: 20px;
  top: 20px;
}

.bm-burger-bars {
  background: #e55041;
}

.bm-menu {
  background: #f2e3d1;
  padding: 2rem;
}

.bm-item {
  display: block;
  color: #2e3191;
  text-decoration: none;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.bm-item:hover {
  color: #2e3191;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .desktop-menu {
    display: none; /* Esconde o menu em telas menores */
  }

  .bm-burger-button {
    display: block; /* Mostra o botão em telas menores */
  }
}
