/* Exhibition.css */

.exhibition-page {
  background-color: #F2E3D1;
  padding: 40px 20px;
  max-width: 1200px;
  margin: auto;
  font-family: 'Inter', sans-serif;
  color: #333;
}

.main-title {
  text-align: center;
  font-size: 2.5em;
  color: #2E3191;
  margin-bottom: 30px;
  font-weight: bold;
}

.exhibition-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exhibition-swiper {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 0px;
}

.exhibition-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.exhibition-text {
  background-color: #ffffffa4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  color: #555;
  font-size: 1.1em;
  font-family: 'Inter', sans-serif;
}

.justify-text {
  text-align: justify;
}

.exhibition-text p {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2em;
  }
  .exhibition-image {
    height: 250px;
  }
  .exhibition-text {
    font-size: 1em;
  }
}
