.event-sponsors {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #F2E3D1;
    padding: 0;
    margin: 0;
}

.event-sponsors-header {
    background-color: #004080;
    color: white;
    text-align: center;
    padding: 20px;
}

.event-sponsors-header h1 {
    margin: 0;
    font-size: 2.5rem;
}

.event-sponsors-content {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
}

.event-sponsor-category {
    margin-bottom: 40px;
}

.event-sponsor-category h2 {
    color: #004080;
    border-bottom: 2px solid #004080;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.event-sponsor-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.event-sponsor-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    flex: 1 1 calc(25% - 20px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-sponsor-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
}

.event-sponsors-footer {
    background: #004080;
    color: white;
    text-align: center;
    padding: 10px;
}

@media (max-width: 768px) {
    .event-sponsor-item {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .event-sponsor-item {
        flex: 1 1 100%;
    }
}