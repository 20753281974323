/* App background and basic styling */
body {
  background-color: #F2E3D1;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F2E3D1;
}

.App {
  flex: 1;
  text-align: center;
}

/* Terms of Use Page Styling */
.terms-of-use {
  font-family: 'Inter', sans-serif;
  color: #333;
  line-height: 1.8;
  padding: 20px;
  max-width: 900px;
  margin: 40px auto;
  background-color: #ffffffa4;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.terms-of-use-section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #2E3191;
  font-weight: 600;
  text-align: left;
}

.section-content {
  padding-left: 20px;
  font-size: 1.1em;
  color: #555;
}

.terms-of-use-list {
  list-style-type: disc;
  padding-left: 40px;
}

.list-item {
  margin-bottom: 15px;
  line-height: 1.6;
}

.justify-text {
  text-align: justify;
}

/* Privacy Policy Page Styling */
.privacy-policy {
  font-family: 'Inter', sans-serif;
  color: #333;
  line-height: 1.8;
  padding: 20px;
  max-width: 900px;
  margin: 40px auto;
  background-color: #ffffffa4;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.privacy-policy-section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #2E3191;
  font-weight: 600;
  text-align: left;
}

.section-content {
  padding-left: 20px;
  font-size: 1.1em;
  color: #555;
}

.privacy-policy-list {
  list-style-type: disc;
  padding-left: 40px;
}

.list-item {
  margin-bottom: 15px;
  line-height: 1.6;
}

.justify-text {
  text-align: justify;
}

/* Cookies Policy Page Styling */
.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.cookie-content {
  text-align: left;
}

.cookie-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.cookie-text {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.cookie-text a {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
}

.cookie-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
}

.accept {
  background-color: #000;
  color: #fff;
}

.reject {
  background-color: #333;
  color: #fff;
}

.settings {
  background: none;
  color: #000;
  text-decoration: underline;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}


/* Add subtle animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cookies-policy {
  animation: fadeIn 0.5s ease-in-out;
}

.commission-container {
  padding: 2rem;
  background-color: #f2f2f2;
  font-family: 'Inter', sans-serif;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.commission-title {
  font-size: 2.5rem;
  color: #2E3191;
  margin-bottom: 1.5rem;
}

.table-wrapper {
  overflow-x: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.commission-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.commission-table th,
.commission-table td {
  padding: 1rem;
  text-align: left;
  font-size: 1rem;
  color: #333;
}

.commission-table th {
  background-color: #2E3191;
  color: #fff;
  font-weight: bold;
}

.commission-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.commission-table tbody tr:hover {
  background-color: #e0e7ff;
  transition: background-color 0.3s ease;
}

.commission-table td {
  border-bottom: 1px solid #e0e0e0;
}
/* Updated Contacts Page Styling */
.contacts-page {
  background-color: #F2E3D1;
  padding: 2rem 1rem;
  font-family: 'Inter', sans-serif;
}

.main-title {
  font-size: 2.5rem;
  color: #2E3191;
  text-align: center;
  margin-bottom: 2rem;
}

.contacts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-card {
  background: linear-gradient(145deg, #ffffff, #f2f2f2);
  border-radius: 12px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.7);
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.15), -6px -6px 20px rgba(255, 255, 255, 0.9);
}

.contact-card h3 {
  font-size: 1.5rem;
  color: #E55041;
  margin-bottom: 1rem;
  font-weight: bold;
}

.contact-card p {
  font-size: 0.8rem;
  color: #555;
  margin: 0.5rem 0;
}

.contact-card a {
  color: #2E3191;
  text-decoration: none;
  font-weight: bold;
}

.contact-card a:hover {
  text-decoration: underline;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .main-title {
    font-size: 2rem;
  }

  .contact-card h3 {
    font-size: 1.3rem;
  }

  .contact-card p {
    font-size: 0.5rem;
  }
}



/* Mobile adjustments */
@media (max-width: 600px) {
  .commission-title {
    font-size: 2rem;
  }

  .commission-table th, .commission-table td {
    font-size: 0.9rem;
    padding: 0.75rem;
  }
}




